import Button from '@atlaskit/button';
import AkForm, { ErrorMessage, Field, FormProps } from '@atlaskit/form';
import Popup, { PopupComponentProps } from '@atlaskit/popup';
import { Box } from '@atlaskit/primitives';
import { CSSObjectWithLabel } from '@atlaskit/react-select';
import Select, { InputActionMeta, PopupSelect, ValueType } from '@atlaskit/select';
import Textfield from '@atlaskit/textfield';
import { captureException } from '@sentry/react';
import debounce from 'lodash.debounce';
import React, { forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';
import { useRelayEnvironment } from 'react-relay';

import { validateNumberFieldWithIntlMessage } from '@townsquare/custom-fields/number-validation';
import { formattedPickerEmptyState } from '@townsquare/i18n';
import { useWorkspaceStore } from '@townsquare/workspace-store';

import {
  ComparatorOperator,
  EmptyStateResolver,
  NumberResolver,
  OnChangeOption,
  OptionTypes,
  Resolver,
  ResolverPopupProps,
  SelectResolver,
  SelectResolverOption,
  SelectResolverOptions,
  SupportedFiltersTypes,
  Unpacked,
} from '../types';

import {
  Form,
  NumberBetweenWrapper,
  NumberResolverContent,
  OptionIcon,
  OptionLabel,
  OptionWrapper,
  POPPER_MODIFIERS,
} from './style';
import { promoteExactTagLabelMatch } from './utils';

export const SelectResolverPopup = ({
  resolver,
  target,
  onChange,
  onClose,
  isOpen,
}: ResolverPopupProps<SelectResolver>) => {
  const environment = useRelayEnvironment();
  const [options, setOptions] = useState<SelectResolverOptions>([]);
  const [querying, setQuerying] = useState(false);
  const [rawQuery, setRawQuery] = useState<string | undefined>(undefined);
  const [{ globalId: workspaceGlobalId, UUID: workspaceUUID }] = useWorkspaceStore();

  // FIXME: TC-4035
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleResolveOptionsForInput = useCallback(
    debounce(query => {
      resolver
        .resolveOptions({ relayEnvironment: environment, query, workspaceId: workspaceGlobalId, workspaceUUID })
        .then(data => {
          setOptions(data);
          setQuerying(false);
        })
        .catch(reason => captureException(reason));
    }, 500),
    [resolver],
  );
  const onInputChange = useCallback(
    (query: string, actionMeta: InputActionMeta) => {
      if (actionMeta.action === 'input-change') {
        if (resolver.type === SupportedFiltersTypes.LABEL) {
          query = query.replace(/[-]+/g, '').trim().length < 1 ? '' : query.replace(/[ ]+/g, '-');
        }
        setQuerying(true);
        setRawQuery(query);
        return handleResolveOptionsForInput(query);
      }
    },
    [handleResolveOptionsForInput, resolver.type],
  );

  useEffect(() => {
    setQuerying(true);
    resolver
      .resolveOptions({ relayEnvironment: environment, workspaceId: workspaceGlobalId, workspaceUUID })
      .then(data => {
        setOptions(data);
        setQuerying(false);
      })
      .catch(reason => captureException(reason));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * While querying continue to match the intermediate results to the text input.
   * When querying is done, show all results since these are already filtered,
   * they could match for various reasons (name string match, email match etc).
   */
  const filterOption = useCallback(
    (option: SelectResolverOption, rawInput: string) =>
      querying ? option.label.toLowerCase().includes(rawInput.toLowerCase()) : true,
    [querying],
  );

  const sortedOptions = useMemo(() => promoteExactTagLabelMatch(options, rawQuery), [options, rawQuery]);

  return (
    <PopupSelect<SelectResolverOption>
      {...formattedPickerEmptyState}
      isOpen={isOpen}
      closeMenuOnSelect={true}
      options={sortedOptions}
      onClose={onClose}
      onMenuClose={onClose}
      isLoading={querying}
      inputValue={rawQuery}
      searchThreshold={-1}
      minMenuWidth={320}
      maxMenuWidth={320}
      placeholder={
        resolver.placeholder ?? (
          <FormattedMessage
            id="townsquare.tql.resolver-popup.placeholder"
            description="Placeholder text for the resolver popup select."
            defaultMessage="Choose a {title}"
            values={{ title: resolver.title.toLowerCase() }}
          />
        )
      }
      target={target}
      inputId="meta-filter-select-input"
      onChange={item => item && onChange(item)}
      filterOption={filterOption}
      onInputChange={onInputChange}
      styles={{
        option: (css, optionProps) => {
          return {
            ...css,
            cursor: optionProps.isDisabled ? 'not-allowed' : 'pointer',
          } as CSSObjectWithLabel;
        },
      }}
      formatOptionLabel={(option, { context }) =>
        context === 'value' ? (
          option.label
        ) : (
          <OptionWrapper data-testid={`label-filter-option-${option.value}`}>
            {option.component ? (
              option.component
            ) : (
              <>
                {!!option.icon && <OptionIcon>{option.icon}</OptionIcon>}
                <OptionLabel>{option.label}</OptionLabel>
              </>
            )}
          </OptionWrapper>
        )
      }
      popperProps={POPPER_MODIFIERS}
    />
  );
};

const EmptyStateResolverPopup = ({ target, resolver }: ResolverPopupProps<EmptyStateResolver>) => {
  return (
    <Popup placement="bottom-start" trigger={target} isOpen={true} content={resolver.render} rootBoundary="viewport" />
  );
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const PopupComponent = forwardRef<HTMLDivElement, PopupComponentProps>(({ ref, ...props }, innerRef) => (
  <NumberResolverContent {...props} innerRef={innerRef as any} />
));

type FormValues = {
  firstValue: string;
  secondValue?: string;
  comparator: ComparatorOperator;
};

const NumberResolverPopup = ({
  resolver,
  target,
  onChange,
  onClose,
  isOpen,
  onComparatorChange,
}: ResolverPopupProps<NumberResolver>) => {
  const firstOption = resolver.filterComparators.at(0);
  const [optionType, setOptionType] = useState(firstOption?.comparatorOption);

  const intl = useIntl();

  // False positive warning
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const validateNumberField = useCallback(validateNumberFieldWithIntlMessage(intl), [intl]);

  const onSelectChange = useCallback(
    (item: { label: string; value: ComparatorOperator }) => {
      if (item.label) {
        setOptionType(item.value);
        onComparatorChange?.(item);
      }
    },
    [onComparatorChange],
  );

  const onSubmit: FormProps<FormValues>['onSubmit'] = useCallback(
    values => {
      const changes = [values.firstValue, values.secondValue]
        .filter(num => num !== '')
        .filter(num => num !== undefined)
        .map(Number);

      if (changes.length !== 0) {
        onChange(changes);
        onClose();
      }
    },
    [onChange, onClose],
  );

  const options = resolver.filterComparators.map(filter => ({
    label: filter.comparatorText ?? '',
    value: filter.comparatorOption,
  }));

  const defaultOption = optionType && { value: optionType, label: firstOption?.comparatorText ?? '' };

  const numberField = useCallback(
    (fieldName: string, setInitialFocusRef: React.Dispatch<React.SetStateAction<HTMLElement | null>>) => (
      <Field aria-required={true} name={fieldName} defaultValue="0" validate={validateNumberField}>
        {({ fieldProps, error }) => (
          <>
            <Textfield type="text" autoComplete="off" {...fieldProps} ref={setInitialFocusRef} />
            {error && (
              <ErrorMessage>
                <Box aria-live="polite">{error}</Box>
              </ErrorMessage>
            )}
          </>
        )}
      </Field>
    ),
    [validateNumberField],
  );

  return (
    <Popup
      isOpen={isOpen ?? false}
      placement="bottom-start"
      popupComponent={PopupComponent}
      onClose={onClose}
      rootBoundary="viewport"
      content={({ setInitialFocusRef }) => (
        <AkForm<FormValues> onSubmit={onSubmit}>
          {({ formProps }) => (
            <Form onSubmit={formProps.onSubmit} onKeyDown={formProps.onKeyDown} innerRef={formProps.ref}>
              <Field<ValueType<Unpacked<typeof options>>> name="comparator">
                {({ fieldProps: { id, onChange, ...rest } }) => (
                  <Select<Unpacked<typeof options>>
                    {...formattedPickerEmptyState}
                    inputId={id}
                    {...rest}
                    defaultValue={defaultOption}
                    options={options}
                    onChange={item => {
                      if (item) {
                        onSelectChange(item);
                      }
                      onChange(item);
                    }}
                  />
                )}
              </Field>
              {optionType === ComparatorOperator.BETWEEN ? (
                <NumberBetweenWrapper>
                  <FormattedMessage
                    id="townsquare.tql.resolver-popup.between"
                    description="Between"
                    defaultMessage="{firstValue} <span>and</span> {secondValue}"
                    values={{
                      firstValue: numberField('firstValue', setInitialFocusRef),
                      secondValue: numberField('secondValue', setInitialFocusRef),
                      span: (chunks: string) => <span>{chunks}</span>,
                    }}
                  />
                </NumberBetweenWrapper>
              ) : (
                numberField('firstValue', setInitialFocusRef)
              )}
              <Button shouldFitContainer type="submit">
                <FormattedMessage
                  id="townsquare.tql.resolver-popup.done"
                  description="Done button"
                  defaultMessage="Done"
                />
              </Button>
            </Form>
          )}
        </AkForm>
      )}
      trigger={target}
    />
  );
};

export const ResolverPopup = <ResolverType extends Resolver>({
  resolver,
  onChange,
  ...props
}: ResolverPopupProps<ResolverType>) => {
  if (resolver.optionType === OptionTypes.SELECT) {
    return (
      <SelectResolverPopup
        resolver={resolver}
        onChange={onChange as (option: OnChangeOption<SelectResolver>) => void}
        {...props}
      />
    );
  } else if (resolver.optionType === OptionTypes.EMPTY) {
    return (
      <EmptyStateResolverPopup
        resolver={resolver}
        onChange={onChange as (option: OnChangeOption<EmptyStateResolver>) => void}
        {...props}
      />
    );
  } else if (resolver.optionType === OptionTypes.NUMBER) {
    return (
      <NumberResolverPopup
        resolver={resolver}
        onChange={onChange as (option: OnChangeOption<NumberResolver>) => void}
        {...props}
      />
    );
  }

  return null;
};
