import AkLegacyButton from '@atlaskit/button';
import Button from '@atlaskit/button/new';
import ShowMoreHorizontalIcon from '@atlaskit/icon/core/show-more-horizontal';
import AddIcon from '@atlaskit/icon/utility/add';
import { CSSObjectWithLabel } from '@atlaskit/react-select';
import { PopupSelect } from '@atlaskit/select';
import { token } from '@atlaskit/tokens';
import React, { useCallback } from 'react';
import { useIntl } from 'react-intl-next';
import { useRouter } from 'react-resource-router';

import { AnalyticsClient, useAnalytics } from '@townsquare/analytics';
import { GOAL_DIRECTORY_PAGE, PROJECT_DIRECTORY_PAGE } from '@townsquare/config/routes';
import MoreIconLegacy from '@townsquare/ds-icon/glyph/more';
import { DynamicOverflowRow } from '@townsquare/dynamic-row';
import { formattedPickerEmptyState } from '@townsquare/i18n';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { Resolver } from '../types';

import { FilterOptionButton } from './FilterOptionButton';
import { ResolverIcon } from './ResolverIcon';
import { AddIcon as LegacyAddIcon } from './icons/Add';
import { FILTER_OPTIONS_BUTTON_STYLE, FilterOptionsWrapper, InlineFlexContainer, POPPER_MODIFIERS } from './style';

// Arbitrary limit to prevent excess renders, but beyond this they will likely be hidden anyway
const MAX_FILTERS_TO_SHOW_BEFORE_OVERFLOW = 15;

export interface FilterOptionsProps {
  resolvers: Resolver[];
  onSelect: (resolver: Resolver) => void;
  mode: 'button' | 'menu';
}

interface FilterOptionMenuOption {
  label: string;
  value: Resolver;
}

type FilterOptionMenuProps = Omit<FilterOptionsProps, 'mode'> & {
  boldButtonText?: boolean;
  useReducedButton?: boolean;
};

export const sendMeatballMenuClickedUiEvent = (
  analytics: AnalyticsClient,
  routeName: string,
  numFieldsInOverflow: number,
) => {
  const context =
    routeName === PROJECT_DIRECTORY_PAGE
      ? 'project'
      : routeName === GOAL_DIRECTORY_PAGE
      ? 'goal'
      : `otherRoute:${routeName}`;
  void analytics.ui('meatballMenu', 'clicked', {
    numFieldsInOverflow,
    context,
  });
};

const FilterOptionMenu = (props: FilterOptionMenuProps) => {
  const intl = useIntl();
  const [routerState] = useRouter();
  const analytics = useAnalytics();
  const isHomeVisualRefreshEnabled = useIsHomeVisualRefreshEnabled();
  const handleButtonClick = useCallback(
    isOpen => {
      if (isOpen) {
        sendMeatballMenuClickedUiEvent(analytics, routerState.route.name, props.resolvers.length);
      }
    },
    [analytics, props.resolvers.length, routerState.route.name],
  );

  const addFilterLabel = intl.formatMessage({
    id: 'townsquare.metafilter.filter-options.add-filter',
    description: 'Button text for adding a filter',
    defaultMessage: 'Add filter',
  });

  return (
    <PopupSelect<FilterOptionMenuOption>
      {...formattedPickerEmptyState}
      options={props.resolvers.map(resolver => ({ value: resolver, label: resolver.title }))}
      styles={{
        option: (css, optionProps) => {
          return {
            ...css,
            cursor: optionProps.isDisabled ? 'not-allowed' : 'pointer',
          } as CSSObjectWithLabel;
        },
      }}
      formatOptionLabel={option => (
        <InlineFlexContainer data-testid={`metafilter-filter-tag-${option.label}`}>
          <ResolverIcon resolver={option.value} />
          {/* eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766 */}
          <span style={{ marginLeft: token('space.100') }}>{option.label}</span>
        </InlineFlexContainer>
      )}
      onChange={item => {
        if (item) {
          props.onSelect(item.value);
        }
      }}
      testId="metafilter-filter-search-menu"
      inputId="metafilter-select-input"
      target={({ ref, isOpen }) => (
        <div ref={ref}>
          {props.useReducedButton ? (
            <AkLegacyButton
              isSelected={isOpen}
              testId="metafilter-add-filter"
              iconAfter={
                <ShowMoreHorizontalIcon
                  label={intl.formatMessage({
                    id: 'townsquare.metafilter.filter-options.add-filter-icon',
                    description: 'Button icon for adding another filter',
                    defaultMessage: 'Add another filter',
                  })}
                  spacing="spacious"
                  LEGACY_fallbackIcon={MoreIconLegacy}
                  color={token('color.icon')}
                />
              }
              // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
              style={{ height: isHomeVisualRefreshEnabled ? '32px' : '40px', width: '40px' }}
              onClick={() => handleButtonClick(isOpen)}
            />
          ) : isHomeVisualRefreshEnabled ? (
            <Button
              isSelected={isOpen}
              testId="metafilter-add-filter"
              iconAfter={AddIcon}
              onClick={() => handleButtonClick(isOpen)}
            >
              {addFilterLabel}
            </Button>
          ) : (
            <AkLegacyButton
              isSelected={isOpen}
              testId="metafilter-add-filter"
              iconAfter={<LegacyAddIcon label="" />}
              style={{
                // eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop, @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
                ...FILTER_OPTIONS_BUTTON_STYLE,
                fontWeight: props.boldButtonText ? token('font.weight.medium') : token('font.weight.regular'),
              }}
              onClick={() => handleButtonClick(isOpen)}
            >
              {addFilterLabel}
            </AkLegacyButton>
          )}
        </div>
      )}
      popperProps={POPPER_MODIFIERS}
    />
  );
};

const FilterOptionButtons = (props: Omit<FilterOptionsProps, 'mode'>) => {
  const intl = useIntl();

  const renderItem = useCallback(
    (resolver: Resolver, idx: number) => {
      return (
        <FilterOptionButton
          key={resolver.type}
          onClick={() => props.onSelect(resolver)}
          testId={`metafilter-add-filter-${resolver.type}`}
          iconBefore={<ResolverIcon resolver={resolver} />}
          title={
            idx === 0
              ? intl.formatMessage(
                  {
                    id: 'townsquare.tql.filter-option-buttons.button-title',
                    description: 'Filter by',
                    defaultMessage: 'Filter by {resolver}',
                  },
                  {
                    resolver: resolver.title,
                  },
                )
              : resolver.title
          }
        />
      );
    },
    [props, intl],
  );

  return (
    <DynamicOverflowRow
      items={props.resolvers}
      renderItem={renderItem}
      renderOverflowMenu={(overflowItems, areAllItemsHidden) => (
        <FilterOptionMenu {...props} resolvers={overflowItems} useReducedButton={!areAllItemsHidden} boldButtonText />
      )}
      maxItemsToShowBeforeOverflow={MAX_FILTERS_TO_SHOW_BEFORE_OVERFLOW}
    />
  );
};

const FilterOptions = (props: FilterOptionsProps) => {
  return (
    <FilterOptionsWrapper>
      {props.mode === 'menu' ? <FilterOptionMenu {...props} /> : <FilterOptionButtons {...props} />}
    </FilterOptionsWrapper>
  );
};

export default FilterOptions;
