import Button from '@atlaskit/button/new';
import { token } from '@atlaskit/tokens';
import React from 'react';
import { FormattedMessage } from 'react-intl-next';
import styled from 'styled-components';

import { useNoWorkspaceExperience } from '@townsquare/workspace-store';

import { useFeedbackModal } from './hook';

// Begin making the "give feedback" button smaller to give space to other nav elements
export const responsiveMobileWidthThreshold = 560;

const Container = styled.div({
  '&:not(:first-child)': {
    marginLeft: token('space.100'),
  },
});

export const GiveFeedbackButton = () => {
  const { openFeedbackModal } = useFeedbackModal();
  const noWorkspaceExperience = useNoWorkspaceExperience();

  const onClick = () =>
    openFeedbackModal({
      redirectOnSave: false,
      feedbackEntryPoint: noWorkspaceExperience ? 'Atlas - No workspace' : 'Atlas - Navigation',
    });

  return (
    <Container data-testid="feedback" onClick={onClick}>
      <Button>
        <FormattedMessage
          id="townsquare.give-feedback-button.give-feedback.label"
          description="Give feedback label"
          defaultMessage="Give feedback"
        />
      </Button>
    </Container>
  );
};
