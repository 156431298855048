import Button, { IconButton, SplitButton } from '@atlaskit/button/new';
import ChevronDownIcon from '@atlaskit/icon/utility/chevron-down';
import ChevronUpIcon from '@atlaskit/icon/utility/chevron-up';
import { Inline, xcss } from '@atlaskit/primitives';
import { CSSObjectWithLabel } from '@atlaskit/react-select';
import { PopupSelect } from '@atlaskit/select';
import Tooltip from '@atlaskit/tooltip';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { SortButtonProps, SortTypes } from './types';
import { findDirectionForOption, findLabelForOption, getSortLabels } from './util';

const groupLabelStyles = xcss({
  textTransform: 'initial',
  font: 'font.body.small',
  marginBlockEnd: 'space.100',
});

type SelectOption = { label: string; value: string };

export function RefreshedSortButton<TSort extends SortTypes>({
  currentSort,
  isDisabled = false,
  sortOptions,
  updateSort,
  testId,
}: SortButtonProps<TSort>) {
  const intl = useIntl();

  const labels = useMemo(() => getSortLabels(sortOptions), [sortOptions]);
  const options: SelectOption[] = useMemo(() => labels.map(label => ({ label, value: label })), [labels]);

  const sortLabel = findLabelForOption(sortOptions, labels, currentSort) || labels[0];
  const sortDirection = findDirectionForOption(currentSort);

  const setCategoryFromOption = (option: SelectOption | null) => {
    if (option) {
      const { label } = option;
      const newSortLabel = Object.keys(sortOptions).find(key => key === label);
      const newSortDirection = sortOptions[label].primaryDirection;

      if (!newSortLabel) {
        return;
      }

      const newSort =
        newSortDirection === 'ASC' ? sortOptions[newSortLabel].ascSort : sortOptions[newSortLabel].descSort;

      if (window.Intercom) {
        window.Intercom('trackEvent', 'directoryChanged');
      }
      updateSort(newSort);
    }
  };

  const toggleSortDirection = () => {
    const newSort = sortDirection === 'ASC' ? sortOptions[sortLabel].descSort : sortOptions[sortLabel].ascSort;

    if (window.Intercom) {
      window.Intercom('trackEvent', 'directoryChanged');
    }
    updateSort(newSort);
  };

  const directionHelpText = intl.formatMessage({
    id: 'townsquare.sort-button.direction.help-text',
    description: 'Help text for the sort direction button',
    defaultMessage: 'Reverse sort order',
  });

  const hasMultipleOptions = options.length > 1;

  const directionButton = (
    <Tooltip content={directionHelpText} position="top">
      <IconButton
        testId={testId ? `${testId}-sort-direction` : undefined}
        isDisabled={isDisabled}
        onClick={toggleSortDirection}
        icon={sortDirection === 'ASC' ? ChevronUpIcon : ChevronDownIcon}
        label={directionHelpText}
      />
    </Tooltip>
  );

  if (!hasMultipleOptions) {
    return directionButton;
  }

  return (
    <SplitButton data-testid={testId}>
      <PopupSelect
        testId={testId ? `${testId}-popup-select` : undefined}
        options={[
          {
            label: intl.formatMessage({
              id: 'townsquare.sort-button.sort-by.popup-select',
              description: 'Label for the sort by dropdown',
              defaultMessage: 'Sort by',
            }),
            options,
          },
        ]}
        onChange={setCategoryFromOption}
        formatGroupLabel={group => <Inline xcss={groupLabelStyles}>{group.label}</Inline>}
        target={({ isOpen, ...triggerProps }) => {
          return (
            <Tooltip
              content={intl.formatMessage({
                id: 'townsquare.sort-button.change-sort-object.tooltip',
                description: 'Tooltip for the change sort object button',
                defaultMessage: 'Change list sorting',
              })}
              position="top"
            >
              <Button
                isDisabled={isDisabled}
                isSelected={isOpen}
                testId={testId ? `${testId}-sort` : undefined}
                {...triggerProps}
              >
                <FormattedMessage
                  id="townsquare.sort-button.sort-by.button"
                  description="Label for the sort by button"
                  defaultMessage="Sort by {label}"
                  values={{
                    label: sortLabel.toLowerCase(),
                  }}
                />
              </Button>
            </Tooltip>
          );
        }}
        popperProps={{ placement: 'bottom-end' }}
        isOptionSelected={option => option.label === sortLabel}
        styles={{ menuList: base => ({ ...base, padding: 0 } as CSSObjectWithLabel) }}
      />
      {directionButton}
    </SplitButton>
  );
}
