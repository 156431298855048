import Button from '@atlaskit/button';
import ArrowDownIcon from '@atlaskit/icon/core/migration/arrow-down';
import ArrowUpIcon from '@atlaskit/icon/core/migration/arrow-up';
import { CSSObjectWithLabel } from '@atlaskit/react-select';
import { PopupSelect } from '@atlaskit/select';
import Tooltip from '@atlaskit/tooltip';
import React, { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl-next';

import { LeftButton, RightButton, SplitButtons } from '@townsquare/directory-header/style';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { RefreshedSortButton } from './RefreshedSortButton';
import { GroupLabel } from './styles';
import type { SortButtonProps, SortTypes } from './types';
import { findDirectionForOption, findLabelForOption, getSortLabels } from './util';

type SelectOption = { label: string; value: string };

export const SortButton = <TSort extends SortTypes>({
  currentSort,
  isDisabled = false,
  sortOptions,
  updateSort,
}: SortButtonProps<TSort>) => {
  const intl = useIntl();
  const isVisuallyRefreshed = useIsHomeVisualRefreshEnabled();

  const labels = useMemo(() => getSortLabels(sortOptions), [sortOptions]);
  const options: SelectOption[] = useMemo(() => labels.map(label => ({ label, value: label })), [labels]);

  const sortLabel = findLabelForOption(sortOptions, labels, currentSort) || labels[0];
  const sortDirection = findDirectionForOption(currentSort);

  const setCategoryFromOption = (option: SelectOption | null) => {
    if (option) {
      const { label } = option;
      const newSortLabel = Object.keys(sortOptions).find(key => key === label);
      const newSortDirection = sortOptions[label].primaryDirection;

      if (!newSortLabel) {
        return;
      }

      const newSort =
        newSortDirection === 'ASC' ? sortOptions[newSortLabel].ascSort : sortOptions[newSortLabel].descSort;

      if (window.Intercom) {
        window.Intercom('trackEvent', 'directoryChanged');
      }
      updateSort(newSort);
    }
  };

  const toggleSortDirection = () => {
    const newSort = sortDirection === 'ASC' ? sortOptions[sortLabel].descSort : sortOptions[sortLabel].ascSort;

    if (window.Intercom) {
      window.Intercom('trackEvent', 'directoryChanged');
    }
    updateSort(newSort);
  };

  const directionHelpText = intl.formatMessage({
    id: 'townsquare.sort-button.direction.help-text',
    description: 'Help text for the sort direction button',
    defaultMessage: 'Reverse sort order',
  });

  const hasMultipleOptions = options.length > 1;

  if (isVisuallyRefreshed) {
    return (
      <RefreshedSortButton
        testId="sort-button"
        currentSort={currentSort}
        isDisabled={isDisabled}
        sortOptions={sortOptions}
        updateSort={updateSort}
      />
    );
  }

  return (
    <SplitButtons>
      {hasMultipleOptions && (
        <PopupSelect
          options={[
            {
              label: intl.formatMessage({
                id: 'townsquare.sort-button.sort-by.popup-select',
                description: 'Label for the sort by dropdown',
                defaultMessage: 'Sort by',
              }),
              options,
            },
          ]}
          onChange={setCategoryFromOption}
          formatGroupLabel={group => <GroupLabel>{group.label}</GroupLabel>}
          target={({ isOpen, ...triggerProps }) => {
            return (
              <Tooltip
                content={intl.formatMessage({
                  id: 'townsquare.sort-button.change-sort-object.tooltip',
                  description: 'Tooltip for the change sort object button',
                  defaultMessage: 'Change list sorting',
                })}
                position="top"
              >
                <LeftButton>
                  <Button isDisabled={isDisabled} isSelected={isOpen} {...triggerProps}>
                    <FormattedMessage
                      id="townsquare.sort-button.sort-by.button"
                      description="Label for the sort by button"
                      defaultMessage="Sort by {label}"
                      values={{
                        label: sortLabel.toLowerCase(),
                      }}
                    />
                  </Button>
                </LeftButton>
              </Tooltip>
            );
          }}
          popperProps={{ placement: 'bottom-end' }}
          isOptionSelected={option => option.label === sortLabel}
          styles={{ menuList: base => ({ ...base, padding: 0 } as CSSObjectWithLabel) }}
        />
      )}
      <Tooltip content={directionHelpText} position="top">
        <RightButton isSplitButton={hasMultipleOptions}>
          <Button
            isDisabled={isDisabled}
            onClick={toggleSortDirection}
            iconAfter={
              sortDirection === 'ASC' ? (
                <ArrowUpIcon color="currentColor" spacing="spacious" label={directionHelpText} />
              ) : (
                <ArrowDownIcon color="currentColor" spacing="spacious" label={directionHelpText} />
              )
            }
          >
            {hasMultipleOptions ? null : sortLabel}
          </Button>
        </RightButton>
      </Tooltip>
    </SplitButtons>
  );
};
